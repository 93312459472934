<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/serveProduct' }"
              >服务产品</el-breadcrumb-item
            >
            <el-breadcrumb-item class="" style="color: #ff6618"
              >服务详情</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div style="background-color: #fafafa;">
      <div class="max_width min_width">
        <div class="Details">
          <div class="Detailsbox">
            <img :src="details.img" class="topimg" style="" alt="" />
            <div class="Detailsboxleft">
              <div
                class="size_24"
                style="line-height: 40px; height: 40px; margin: 10px 0px"
              >
                {{ details.product_name }}
              </div>
              <div class="dis font_text h_40">
                <div class="size_18">价格</div>
                
                <span class="size_24 marl_20 c417" v-if="details.mode_price">{{details.mode_price}}元</span>
                <span class="size_24 marl_20 c417">免费</span>
              </div>
              <div class="size_18 dis h_40 size_18" style="color: #808080">
                <span style="padding-right: 10px">{{ details.num }}次申请</span
                >|
                <span style="padding-left: 10px"
                  >{{ details.number }}次访问</span
                >
              </div>
              <div class="font_text h_40 size_18 dis">
                <div class="size18" style="width: 200px">
                  服务类型： {{ details.category_name }}
                </div>
                <div class="size18">服务对象：{{ details.object }}</div>
              </div>
              <div class="font_text h_40 dis size_18">
                <div  v-if="details.mode === 1">
                  服务模式：公益性服务
                </div>
                <div  v-else-if="details.mode === 2">
                  服务模式：市场化服务
                </div>
                <div  v-else-if="details.mode === 3">
                  服务模式：公益性市场化服务
                </div>
                <div style="margin-left: 20px;">服务地区：{{ details.city_name }}</div>
              </div>
              <div class="font_text h_40 dis size_18">
                <div style="width: 200px">联系人：{{ details.contact }}</div>
                <div class="c417">联系电话：{{ details.mobile }}</div>
              </div>
              <div class="font_text h_40 dis size_18">
                邮箱：
                <div>{{ details.email }}</div>
              </div>
              <el-button class="Detailsbtn" @click="serve()"
                >点击申请服务</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #fafafa; padding: 30px 0px">
        <div class="max_width min_width">
          <div class="presentationbox">
            <div class="pad_30">
              <div class="dis align_center">
                <div class="icon"></div>
                <div class="marl_20 size_18 bold h_40">产品介绍</div>
              </div>
              <div class="presentation" v-html="details.content"></div>
            </div>

            <div>
              <div class="informationbox">
                <div class="dis align_center pad_30">
                  <div class="icon"></div>
                  <div class="marl_20 size_18 bold h_40">服务机构信息</div>
                </div>
                <div class="flex" style="margin: 0px 30px 30px 30px">
                  <div v-if="servemessage.img">
                    <img :src="servemessage.img" class="serveimg" alt="" />
                  </div>

                  <div v-else>
                    <img
                      src="../../assets/image/topimg.png"
                      class="serveimg"
                      alt=""
                    />
                  </div>

                  <div class="" style="padding: 10px">
                    <div
                      class="size_22 bold"
                      style="line-height: 30px; padding: 10px 0px"
                    >
                      {{ servemessage.name }}
                    </div>
                    <div
                      class="size_18 bold"
                      style="line-height: 30px; padding: 10px 0px"
                    >
                      联系电话：{{ servemessage.mobile }}
                    </div>
                  </div>
                </div>
                <div style="text-align: center">
                  <el-button class="btn" @click="godetails()"
                    >服务机构详情</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: "",
      details: {},
      id: 0,
      mid: "",
      servemessage: {},
    };
  },
  created() {
    console.log(this.$route.query, "this.$route.query.id");
    this.id = this.$route.query.id
      ? this.$route.query.id
      : this.$route.query.productid;
    console.log(this.id, "4561511231");
    this.getproduct();
    this.addnum(); //进来添加一次访问次数
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  methods: {
    serve() {
      let index = localStorage.logintype;
      console.log(index);
      if (index == 1) {
        this.$confirm("是否申请服务?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.authentication();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$message({
          type: "warning",
          message: "该产品只限企业用户申请",
        });
      }
    },

    postorder_add() {
      let data = {
        token: localStorage.eleToken,
        service_product_id: this.id,
      };

      this.$post("/product_order_add", data).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
      });
    },

    //认证
    authentication() {
      const index = localStorage.logintype;
      if (index == 2 || index == 3) {
        this.$message({
          type: "warning",
          message: "该产品只限企业用户申请",
        });
      } else {
        let data = {
          token: localStorage.eleToken,
        };
        console.log(index, "index");
        console.log(data, "data");
        this.$post("/select_enterprise_status", data).then((res) => {
          console.log(res, "res");
          if (res.result === 2) {
            this.postorder_add();
          } else if (res.result === 0 || res.result === 3) {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            location.href = "https://sso.smeqh.cn/realname/index";
          } else if (res.result === 1) {
            this.$message({
              type: "info",
              message: "您的企业正在认领中，审核通过之后才能进行下一步操作",
            });
          }
        });
      }
    },

    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },
    //获取服务详情
    getproduct() {
      console.log(this.id, "this.id ");
      let data = { id: this.id };

      this.$get("/service_product_info", data).then((res) => {
        this.details = res.result;
        console.log(this.details, "this.details");
        this.mid = res.result.mechanism_id;
        this.getmechanism();
      });
    },
    //获取服务机构信息
    getmechanism() {
      let data = {
        id: this.mid,
        // id: 1,
      };
      this.$get("/service_product_mechanism", data).then((res) => {
        console.log(res, "resmechanism");
        this.servemessage = res.result;
      });
    },
    addnum() {
      let data = { id: this.id };

      this.$get("/service_product_number", data).then((res) => {
        console.log(res, "res");
        // this.details = res.result;
      });
    },
    godetails() {
      this.$router.push({ path: "/serveIntroduce", query: { id: this.mid } });
    },
  },
};
</script>
<style lang="less" scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.h_40 {
  height: 40px;
  line-height: 40px;
}

.btn {
  width: 400px;
  height: 50px;
  background: #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
}

.img {
  width: 120px;
  height: 120px;
  // background: #ffddcc;
  // border-radius: 5px;
  // box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}

.Details {
  width: 1518px;
  background: #ffffff;
  border-radius: 5px;

  .Detailsbox {
    display: flex;
    padding: 30px;
  }

  .topimg {
    width: 568px;
    height: 360px;
    // background: #ffddcc;
    // border-radius: 5px;
    // box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
  }

  .Detailsboxleft {
    margin-left: 30px;

    .Detailsbtn {
      margin-top: 20px;
      background-color: #ff6618;
      color: #fefefe;
    }
  }
}

.serveimg {
  width: 120px;
  height: 120px;
  // background: #ffddcc;
  border-radius: 5px;
  // box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}

.presentationbox {
  width: 1028px;
  /* height: 1030px; */
  background: #ffffff;
  border-radius: 5px;
  display: flex;

  .presentation {
    width: 968px;
    background: #ffffff;
    // border-radius: 5px;
    // box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
  }

  .informationbox {
    width: 420px;
    // height: 312px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    margin-left: 20px;
  }
}
</style>